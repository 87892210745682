import logo_img from "../assets/favicon_SHI_up_v2.jpg";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

export const Navigation = (props) => {
    return (
        <Box
            id="menu"
            className="navbar navbar-default navbar-fixed-top"
            sx={{ zIndex: 999 }}
        >
            <div className="container">
                <div className="navbar-header">
                    <button
                        type="button"
                        className="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#bs-example-navbar-collapse-1"
                    >
                        {" "}
                        <span className="sr-only">Toggle navigation</span>{" "}
                        <span className="icon-bar"></span>{" "}
                        <span className="icon-bar"></span>{" "}
                        <span className="icon-bar"></span>{" "}
                    </button>
                    <a className="page-scroll" href="#page-top">
                        <img
                            src={logo_img}
                            className="img-responsive"
                            style={{ height: "50px" }}
                            alt=""
                        />{" "}
                    </a>{" "}
                    {/* <img src={logo_img} className='' alt='' />{' '} */}
                </div>

                <div
                    className="collapse navbar-collapse"
                    id="bs-example-navbar-collapse-1"
                >
                    <ul className="nav navbar-nav navbar-right">
                        <li>
                            <a href="#research" className="page-scroll">
                                Research
                            </a>
                        </li>
                        <li>
                            <a href="#team" className="page-scroll">
                                Team
                            </a>
                        </li>                        
                        <li>
                            <Link
                                to="/publications"
                                href="#publications"
                                className="page-scroll"
                            >
                                Publications
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/alumni"
                                className="page-scroll"
                            >
                                Alumni
                            </Link>
                        </li>                        
                    </ul>
                </div>
            </div>
        </Box>
    );
};
