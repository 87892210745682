import * as React from "react";
import logo_img from "../assets/favicon_SHI_up_v2.jpg";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import data from "../data/publications.json";
import init_rule from "../data/publication_filter_rule.json";
import TextField from "@mui/material/TextField";
import { useMediaQuery } from "react-responsive";
import { ScrollRestoration } from "react-router-dom";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import { useParams } from "react-router-dom";

const Nav = () => (
    <Box
        id="menu"
        className="navbar navbar-default navbar-fixed-top"
        sx={{ zIndex: 999 }}
    >
        <div className="container">
            <div className="navbar-header">
                <button
                    type="button"
                    className="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#bs-example-navbar-collapse-1"
                >
                    {" "}
                    <span className="sr-only">Toggle navigation</span>{" "}
                    <span className="icon-bar"></span>{" "}
                    <span className="icon-bar"></span>{" "}
                    <span className="icon-bar"></span>{" "}
                </button>
                <Link to="/">
                    <img
                        src={logo_img}
                        className="img-responsive"
                        style={{ height: "50px" }}
                        alt=""
                    />{" "}
                </Link>{" "}
            </div>

            <div
                className="collapse navbar-collapse"
                id="bs-example-navbar-collapse-1"
            >
                <ul className="nav navbar-nav navbar-right">
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                </ul>
            </div>
        </div>
    </Box>
);

const testTags = (tags, k) => {
    let isInclude = false;
    tags.forEach((tag, idx, arr) => {
        if (tag.toLowerCase().includes(k)) {
            isInclude = true;
        }
    });

    return isInclude;
};

export const Publications = (props) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1000px)",
    });

    let { q } = useParams();
    const [list, setList] = React.useState(data);
    const [filterRule, setFilterRule] = React.useState(()=>{
        if(q !== undefined){
            return {...init_rule, [q]: true}
        }else{
            return init_rule
        }
    });
    const [filterStr, setFilterStr] = React.useState("");

    React.useEffect(() => {
        // filter rule
        let includeAll = true;
        Object.keys(filterRule).forEach(k=>{
            if(filterRule[k]){
                includeAll = false;
            }
        })
        let filteredList = undefined;
        if(includeAll){
            filteredList = data;
        }else{
            filteredList = data.filter((elem) => {
                let isInclude = false;
                Object.keys(filterRule).forEach((k) => {
                    if (!isInclude && filterRule[k]) {
                        isInclude = isInclude || testTags(elem.tags, k.toLowerCase());
                    }
                });
                return isInclude;
            });
        }

        // filter str
        filteredList = filteredList.filter(
            (elem) =>
                elem.title.toLowerCase().includes(filterStr) ||
                elem.author.toLowerCase().includes(filterStr) ||
                elem.bib.toLowerCase().includes(filterStr) ||
                testTags(elem.tags, filterStr)
        );        
        
        filteredList.sort((a,b) => new Date(b.date) - new Date(a.date));

        setList(filteredList)

    }, [filterRule, filterStr]);

    return (
        <>
            <Nav />
            <Box id="publications" className="text-center" sx={{ mt: "80px" }}>
                <div className="container">
                    <Box className="section-title" sx={{ mb: "30px" }}>
                        <h2>Publications</h2>
                    </Box>
                    <div className="row">
                        <Box
                            direction="row"
                            spacing={1}
                            sx={{px: "5px", mb: "10px"}}
                        >
                            {Object.keys(filterRule).map((k, idx) => (
                                <Chip
                                    key={idx}
                                    label={k}
                                    variant={filterRule[k] ? "" : "outlined"}
                                    onClick={() => {
                                        setFilterRule({
                                            ...filterRule,
                                            [k]: !filterRule[k],
                                        });
                                    }}
                                    sx={{margin: "0 5px 5px 0"}}
                                />
                            ))}
                        </Box>
                        <TextField
                            sx={
                                isDesktopOrLaptop
                                    ? { width: "40%" }
                                    : { width: "80%" }
                            }
                            id="outlined-basic"
                            label="Filter"
                            variant="outlined"
                            placeholder="e.g. CCNet, creative"
                            onChange={(e) => {
                                setFilterStr(e.target.value.toLowerCase());
                            }}
                        />
                        <List
                            sx={{
                                width: "100%",
                                maxWidth: 960,
                                margin: "auto",
                                bgcolor: "background.paper",
                            }}
                        >
                            {list.map((d, i) => (
                                <div key={`${d.name}-${i}`}>
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar
                                            sx={{ mt: "4px", mr: "10px" }}
                                        >
                                            <Avatar
                                                alt={d.title}
                                                src={d.thumbnail}
                                                sx={{ width: 100, height: 100 }}
                                                variant="square"
                                            />
                                        </ListItemAvatar>
                                        <div>
                                            <a href={d.url}>
                                                <Typography>
                                                    {d.title} ({d.date.slice(-4)})
                                                </Typography>
                                            </a>

                                            <Typography
                                                sx={{ fontSize: "0.9rem" }}
                                            >
                                                {d.author}
                                            </Typography>
                                            <Typography
                                                sx={{ fontSize: "0.9rem" }}
                                            >
                                                {d.bib}
                                            </Typography>
                                            <Typography
                                                sx={{ fontSize: "0.9rem" }}
                                            >
                                                Tags: {d.tags.join(", ")}
                                            </Typography>
                                        </div>
                                    </ListItem>
                                    {i < list.length - 1 && (
                                        <Divider
                                            variant="middle"
                                            component="li"
                                        />
                                    )}
                                </div>
                            ))}
                        </List>
                    </div>
                </div>
            </Box>
            <ScrollRestoration />
        </>
    );
};
