import Divider from "@mui/material/Divider";
export const Footer = (props) => {
    return (
        <div>
            <Divider variant="middle" component="div" />
            <div id="footer">
                <div className="container text-center">
                    &copy; {new Date().getFullYear()} SHI-Labs
                </div>
            </div>
        </div>
    );
};
