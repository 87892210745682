import Grid from "@mui/material/Grid";
import { CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";

export const Team = (props) => {
    return (
        <Box id="team" className="text-center" sx={{ pt: "80px" }}>
            <div className="container">
                <div className="col-md-8 col-md-offset-2 section-title">
                    <h2>Team</h2>
                </div>
                <div id="row">
                    {props.data
                        ? props.data.map((d, i) => (
                              <div
                                  key={`${d.name}-${i}`}
                                  className="col-md-3 col-sm-6 team"
                              >
                                  <div className="thumbnail">
                                      {" "}
                                      <CardActionArea href={d.url}>
                                          <img
                                              src={d.img}
                                              alt="avatar"
                                              className="team-img"
                                          />
                                      </CardActionArea>
                                      <div className="caption">
                                          <h4>{d.name}</h4>
                                          <p>{d.job}</p>
                                      </div>
                                  </div>
                              </div>
                          ))
                        : "loading"}
                </div>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Link to="/alumni">Alumni</Link>
                    </Grid>
                </Grid>
            </div>
        </Box>
    );
};
