import * as React from "react";
import ParticlesBg from "particles-bg";
import Box from "@mui/material/Box";

const bgTypes = [
  // "color",
  "lines",
  // "thick",
  "cobweb",
  // "polygon",
  "circle",
  // "square",
  // "fountain",
];

export const Header = (props) => {
  const [bgType, setBgType] = React.useState("circle");

  React.useEffect(() => {
    const interval = setInterval(function () {
      setBgType(bgTypes[Math.floor(Math.random() * bgTypes.length)]);
    }, 7000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <header id="header">
      <div className="intro">
        <ParticlesBg
          type={bgType}
          bg={{ zIndex: 0, position: "absolute", top: 0 }}
        />
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  <Box sx={{ textTransform: "capitalize" }}>
                    {props.data ? props.data.title : "Loading"}
                    <span></span>
                  </Box>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                {/* <a
                                    href="#features"
                                    className="btn btn-custom btn-lg page-scroll"
                                >
                                    Learn More
                                </a>{" "} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
