import logo_img from "../assets/favicon_SHI_up_v2.jpg";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import JsonData from "../data/data.json";
import data from "../data/alumni.json";
import { ScrollRestoration } from "react-router-dom";
import { CardActionArea } from "@mui/material";
import styled from "styled-components";

const StyledAchor = styled.a`
  font-size: 18px;
  color: #333;
  font-weight: 600;
`;

const Nav = () => (
  <Box
    id="menu"
    className="navbar navbar-default navbar-fixed-top"
    sx={{ zIndex: 999 }}
  >
    <div className="container">
      <div className="navbar-header">
        <button
          type="button"
          className="navbar-toggle collapsed"
          data-toggle="collapse"
          data-target="#bs-example-navbar-collapse-1"
        >
          {" "}
          <span className="sr-only">Toggle navigation</span>{" "}
          <span className="icon-bar"></span> <span className="icon-bar"></span>{" "}
          <span className="icon-bar"></span>{" "}
        </button>
        <Link to="/">
          <img
            src={logo_img}
            className="img-responsive"
            style={{ height: "50px" }}
            alt=""
          />{" "}
        </Link>{" "}
      </div>

      <div
        className="collapse navbar-collapse"
        id="bs-example-navbar-collapse-1"
      >
        <ul className="nav navbar-nav navbar-right">
          <li>
            <Link to="/">Home</Link>
          </li>
        </ul>
      </div>
    </div>
  </Box>
);

export const Alumni = (props) => {
  return (
    <>
      <Nav />
      <Box id="team" className="text-center" sx={{ mt: "80px" }}>
        <div className="container">
          <div className="col-md-8 col-md-offset-2 section-title">
            <h2>Current</h2>
          </div>
          <div id="row">
            {JsonData.Team
              ? JsonData.Team.map((d, i) => (
                  <div
                    key={`${d.name}-${i}`}
                    className="col-md-3 col-sm-6 team"
                  >
                    <div className="thumbnail">
                      {" "}
                      <CardActionArea href={d.url}>
                        <img src={d.img} alt="avatar" className="team-img" />
                      </CardActionArea>
                      <div className="caption">
                        <h4>{d.name}</h4>
                        <p>{d.job}</p>
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
          <div className="col-md-8 col-md-offset-2">
            <h2>Alumni</h2>
          </div>
          <div id="row">
            {data
              ? data.map((d, i) => (
                  <div
                    key={`${d.name}-${i}`}
                    className="col-lg-3 col-md-4 col-sm-6 team"
                  >
                    <div className="thumbnail">
                      <div className="caption">
                        <h4>
                          <StyledAchor href={d.url}>{d.name}</StyledAchor>
                        </h4>
                        <div>{d.degree}</div>
                        <div>{d.job}</div>
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </Box>
      <ScrollRestoration />
    </>
  );
};
