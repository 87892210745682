import * as React from "react";
import logo_img from "../assets/favicon_SHI_up_v2.jpg";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import raw_data from "../data/researches.json";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ScrollRestoration } from "react-router-dom";

const process_data = () => {
    let processed = [];
    raw_data.all.forEach((elem, idx) => {
        elem.idx = idx;
        processed.push(elem);
    });
    return processed;
};

const data = process_data();

const testLinks = (links, k) => {
    let isInclude = false;
    links.forEach((elem, idx, arr) => {
        if (elem.type.toLowerCase().includes(k)) {
            isInclude = true;
        }
    });

    return isInclude;
};

const testTags = (tags, k) => {
    let isInclude = false;
    tags.forEach((tag, idx, arr) => {
        if (tag.toLowerCase().includes(k)) {
            isInclude = true;
        }
    });

    return isInclude;
};

const Nav = () => (
    <Box id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
            <div className="navbar-header">
                <button
                    type="button"
                    className="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#bs-example-navbar-collapse-1"
                >
                    {" "}
                    <span className="sr-only">Toggle navigation</span>{" "}
                    <span className="icon-bar"></span>{" "}
                    <span className="icon-bar"></span>{" "}
                    <span className="icon-bar"></span>{" "}
                </button>
                <Link to="/">
                    <img
                        src={logo_img}
                        className="img-responsive"
                        style={{ height: "50px" }}
                        alt=""
                    />{" "}
                </Link>{" "}
            </div>

            <div
                className="collapse navbar-collapse"
                id="bs-example-navbar-collapse-1"
            >
                <ul className="nav navbar-nav navbar-right">
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                </ul>
            </div>
        </div>
    </Box>
);

const BackdropImg = styled.img`
    max-width: 90%;
`;


const CardGrid = ({ data }) => {
    const OneCard = ({ elem }) => {
        const [open, setOpen] = React.useState(false);
        const handleClose = () => {
            setOpen(false);
        };
        const handleToggle = () => {
            setOpen(!open);
        };

        return (
            <Grid item xs={12} sm={6} md={4}>
                <Card
                    variant={"outlined"}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <CardActionArea onClick={handleToggle}>
                        <CardMedia
                            component="img"
                            height="200"
                            image={elem.img}
                            alt=""
                        />
                    </CardActionArea>

                    <CardContent sx={{ flexGrow: 1 }}>
                        <Typography
                            noWrap
                            gutterBottom
                            variant="h5"
                            component="p"
                            sx={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                            }}
                        >
                            {elem.title}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                                display: "-webkit-box",
                                overflow: "hidden",
                                WebkitLineClamp: "6",
                                WebkitBoxOrient: "vertical",
                            }}
                        >
                            {elem.abstract}
                        </Typography>
                    </CardContent>
                    <Box sx={{ height: "65px" }}>
                        {elem.links.length > 5 ? (
                            elem.links.map((elem, idx) => (
                                <Button
                                    key={`${elem}-${idx}`}
                                    size="small"
                                    href={elem.url}
                                    sx={{ color: "gray" }}
                                >
                                    {elem.type}
                                </Button>
                            ))
                        ) : (
                            <Box sx={{my: "20px"}}>
                                {elem.links.map((elem, idx) => (
                                    <Button
                                        key={`${elem}-${idx}`}
                                        size="small"
                                        href={elem.url}
                                        sx={{ color: "gray" }}
                                    >
                                        {elem.type}
                                    </Button>
                                ))}
                            </Box>
                        )}
                    </Box>
                </Card>
                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={open}
                    onClick={handleClose}
                >
                    <BackdropImg
                        src={elem.img}
                        alt={elem.title}
                        loading="lazy"
                    />
                </Backdrop>
            </Grid>
        );
    };

    return (
        <Container sx={{ py: 8, textAlign: "left" }} maxWidth="lg">
            <Grid container spacing={4} direction="row" justifyContent="center">
                {data.map((elem, idx) => (
                    <OneCard key={idx} elem={elem} />
                ))}
            </Grid>
        </Container>
    );
};

export const ResearchesGrid = (props) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1000px)",
    });
    let { q } = useParams();
    const [researchList, setResearchList] = React.useState((prev) => {
        if (q !== undefined) {
            q = q.toLowerCase();
            return data.filter(
                (elem) =>
                    elem.title.toLowerCase().includes(q) ||
                    elem.abstract.toLowerCase().includes(q) ||
                    testTags(elem.tags, q) ||
                    testLinks(elem.links, q)
            );
        } else {
            return data;
        }
    });

    const [timer, setTimer] = React.useState(null);
    const handleChange = (event) => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            let k = event.target.value.toLowerCase();

            const isSameSet = (s1, s2) => {
                const getComp = (v) => JSON.stringify([...v].sort());
                return getComp(s1) === getComp(s2);
            };

            if (k.length > 1) {
                // the order of filter list will always be the same for the key.
                let filteredList = data.filter(
                    (elem) =>
                        elem.title.toLowerCase().includes(k) ||
                        elem.abstract.toLowerCase().includes(k) ||
                        testTags(elem.tags, k) ||
                        testLinks(elem.links, k)
                );

                if (!isSameSet(new Set(researchList), new Set(filteredList))) {
                    setResearchList(filteredList);
                }
            } else if (k.length === 0) {
                if (!isSameSet(new Set(researchList), new Set(data))) {
                    setResearchList(data);
                }
            }
        }, 500);

        setTimer(newTimer);
    };

    return (
        <>
            <Nav />
            <Box
                id="researches_grid"
                className="text-center"
                sx={{ mt: "80px" }}
            >
                <div className="container">
                    <div className="section-title">
                        <h2>Researches</h2>
                    </div>
                </div>
                <div className="row">
                    <Box>
                        <TextField
                            sx={
                                isDesktopOrLaptop
                                    ? { width: "40%" }
                                    : { width: "80%" }
                            }
                            id="outlined-basic"
                            label="Filter"
                            variant="outlined"
                            placeholder="e.g. versitile diffusion, code, dataset etc."
                            onChange={handleChange}
                            defaultValue={q}
                        />
                    </Box>

                    <CardGrid data={researchList} />
                </div>
            </Box>
            <ScrollRestoration />
        </>
    );
};
