import React from "react";

import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Researches } from "./components/researches";
import { Alumni } from "./components/alumni";
import { Publications } from "./components/publications";
import { ResearchesGrid } from "./components/researches_grid";
import { Team } from "./components/team";
import { Footer } from "./components/footer";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";

import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});

const theme = createTheme();

const Home = () => {
    return (
        <>
            <Navigation />
            <Header data={JsonData.Header} />
            <Researches />
            <Team data={JsonData.Team}></Team>
        </>
    );
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "alumni",
        element: <Alumni />,
    },
    {
        path: "publications",
        element: <Publications />,
    },
    {
        path: "publications/:q",
        element: <Publications />,
    },
    {
        path: "researches",
        element: <ResearchesGrid />,
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
        <Footer />
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
