import * as React from "react";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import data from "../data/researches.json";

import "react-awesome-slider/dist/styles.css";
import styled from "styled-components";
import { CardActionArea } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { Link } from "react-router-dom";

const BackdropImg = styled.img`
    max-width: 90%;
`;

const BackdropAbs = styled.p`
    max-width: 90%;
    font-size: 1rem;
`;

const testTags = (tags, k) => {
    let isInclude = false;
    k = k.toLowerCase();
    tags.forEach((tag, idx, arr) => {
        if (tag.toLowerCase().includes(k)) {
            isInclude = true;
        }
    });

    return isInclude;
};

const CardGrid = ({ data, path, isPaddingBot = true }) => {
    const OneCard = ({ elem }) => {
        const [imgOpen, setImgOpen] = React.useState(false);
        const [absOpen, setAbsOpen] = React.useState(false);

        return (
            <Grid item xs={12} sm={6} md={4}>
                <Card
                    variant={"outlined"}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <CardActionArea
                        onClick={() => {
                            setImgOpen(!imgOpen);
                        }}
                    >
                        <CardMedia
                            component="img"
                            height="200"
                            image={elem.img}
                            alt=""
                        />
                    </CardActionArea>
                    <CardActionArea
                        onClick={() => {
                            setAbsOpen(!imgOpen);
                        }}
                    >
                        <CardContent sx={{ flexGrow: 1 }}>
                            <Typography
                                noWrap
                                gutterBottom
                                variant="h5"
                                component="p"
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                }}
                            >
                                {elem.title}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                    display: "-webkit-box",
                                    overflow: "hidden",
                                    WebkitLineClamp: "6",
                                    WebkitBoxOrient: "vertical",
                                }}
                            >
                                {elem.abstract}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <Box sx={{ height: "55px"}}>
                        {elem.links.length > 5 ? (
                            elem.links.map((elem, idx) => (
                                <Button
                                    key={`${elem}-${idx}`}
                                    size="small"
                                    href={elem.url}
                                    sx={{ color: "gray", fontSize: "0.7rem" }}
                                >
                                    {elem.type}
                                </Button>
                            ))
                        ) : (
                            <Box sx={{ my: "15px" }}>
                                {elem.links.map((elem, idx) => (
                                    <Button
                                        key={`${elem}-${idx}`}
                                        size="small"
                                        href={elem.url}
                                        sx={{ color: "gray", fontSize: "0.7rem" }}
                                    >
                                        {elem.type}
                                    </Button>
                                ))}
                            </Box>
                        )}
                    </Box>
                </Card>
                <Backdrop
                    sx={{
                        color: "#fff",
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={imgOpen}
                    onClick={() => {
                        setImgOpen(false);
                    }}
                >
                    <BackdropImg
                        src={elem.img}
                        alt={elem.title}
                        loading="lazy"
                    />
                </Backdrop>
                <Backdrop
                    sx={{
                        color: "#fff",
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={absOpen}
                    onClick={() => {
                        setAbsOpen(false);
                    }}
                >
                    <BackdropAbs>{elem.abstract}</BackdropAbs>
                </Backdrop>
            </Grid>
        );
    };

    return (
        <Container
            sx={
                isPaddingBot
                    ? { py: 4, textAlign: "left" }
                    : { pt: 4, textAlign: "left" }
            }
            maxWidth="lg"
        >
            <Grid container spacing={4} direction="row" justifyContent="center">
                {data.map((elem, idx) => (
                    <OneCard key={idx} elem={elem} />
                ))}
            </Grid>
            <Grid container justifyContent="flex-end">
                <Grid item sx={{ py: "1rem" }}>
                    <Link to={`/publications/${path}`} className="page-scroll">
                        More
                    </Link>
                </Grid>
            </Grid>
        </Container>
    );
};

export const Researches = (props) => {
    return (
        <Box id="research" className="text-center" sx={{ pt: "80px" }}>
            <h2>
                <Box sx={{ textTransform: "capitalize" }}>
                    Research Highlights
                </Box>
            </h2>
            <Box className="col-md-10 col-md-offset-1" sx={{ pt: "20px" }}>
                <Typography
                    sx={{ textTransform: "capitalize", fontSize: "1.5rem" }}
                >
                    Accurate & Efficient Vision
                </Typography>
                <p>
                    Pushing the envelope of cutting-edge AI algorithms and
                    systems to the next level
                </p>
            </Box>
            <CardGrid
                data={data.all
                    .filter((elem) =>
                        testTags(elem.tags, "Accurate & Efficient Vision")
                    )
                    .slice(0, 3)}
                path="Accurate & Efficient Vision"
                // sx={{pb: "10px"}}
            />

            <Box className="col-md-10 col-md-offset-1">
                <Typography
                    sx={{ textTransform: "capitalize", fontSize: "1.5rem" }}
                >
                    Creative AI
                </Typography>
                <p>Empowering the next generation of creative commmunication</p>
            </Box>
            <CardGrid
                data={data.all
                    .filter((elem) => testTags(elem.tags, "Creative AI"))
                    .slice(0, 3)}
                path="Creative AI"
            />
            <Box className="col-md-10 col-md-offset-1">
                <Typography
                    sx={{ textTransform: "capitalize", fontSize: "1.5rem" }}
                >
                    Responsible AI
                </Typography>

                <p>Solving important real-world problems at scale</p>
            </Box>
            <CardGrid
                data={data.all
                    .filter((elem) => testTags(elem.tags, "Responsible AI"))
                    .slice(0, 3)}
                path="Responsible AI"
                isPaddingBot={false}
            />
        </Box>
    );
};
